import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";

import { SEO } from "@app-components/header/SEO";
import MainLayout from "@app-components/layout/MainLayout";
import { WEB_TYPE, WEB_TYPE_NAME } from "@app-constants/common";
import useAuth from "@app-hook/useAuth";
import { themeModeAtom } from "@app-jotai/layout/getters";
import { PATHS, getRoutes } from "@app-utils/routes";

import GBALogin from "./auth/GBALogin";
import Login from "./auth/Login";
import PrivatePage from "./auth/PrivatePage";
import Register from "./auth/Register";
// import CartPage from "./cart/CartPage";
// import CheckoutPage from "./checkout/CheckoutPage";
// import NewCheckoutPage from "./checkout/NewCheckoutPage";
import EmailResetPassword from "./email/EmailResetPassword";
import EmailVerification from "./email/EmailVerification";
import AfterSalesSupportPage from "./faq/AfterSalesSupportPage";
import DeliverySupportPage from "./faq/DeliverySupportPage";
import Faq from "./faq/FaqPage";
import PartnershipSupportPage from "./faq/PartnershipSupportPage";
import ProductSupportPage from "./faq/ProductSupportPage";
import FestivalDemo from "./festival-demo/FestivalDemo";
import AgencyLandingPage from "./landing-page/AgencyLandingPage";
import GBALandingPage from "./landing-page/GBALandingPage";
// import LandingPage from "./landing-page/LandingPage";
import NewLandingPage from "./landing-page/NewLandingPage";
import PNSLandingPage from "./landing-page/PNSLandingPage";
// import OrdersHistory from "./order/OrdersHistory";
import PreOrderPolicyPage from "./order/PreOrderPolicyPage";
import AboutUsPage from "./others/AboutUsPage";
import ComingSoon from "./others/ComingSoon";
import ContactPage from "./others/ContactPage";
import PasswordForget from "./password/PasswordForget";
import Legal from "./policies/Legal";
import PaymentPolicyPage from "./policies/PaymentPolicyPage";
import Privacy from "./policies/Privacy";
import Refund from "./policies/Refund";
import ReturnsPolicyPage from "./policies/ReturnsPolicyPage";
import ShippingPolicyPage from "./policies/ShippingPolicyPage";
import Terms from "./policies/Terms";
import WarrantyPolicyPage from "./policies/WarrantyPolicyPage";
// import ProductDetail from "./product/ProductDetail";
import Profile from "./profile/Profile";
import SettingPage from "./setting/SettingPage";
import AccessControlPage from "./userGuide/AccessControlPage";
import ForPaymentPage from "./userGuide/ForPaymentPage";
import GetRingSizePage from "./userGuide/GetRingSizePage";
import UsingRingPage from "./userGuide/UsingRingPage";

export default function Root() {
  const { t } = useTranslation();
  const theme = useAtomValue(themeModeAtom);
  const routes = getRoutes(t);

  useAuth();

  const roots = {
    [WEB_TYPE_NAME.AGENCY]: (
      <Route path={routes.home.path} element={<AgencyLandingPage />} />
    ),
    [WEB_TYPE_NAME.PNS]: (
      <>
        <Route path={routes.home.path} element={<PNSLandingPage />} />
        <Route
          path={routes.partnershipSupport.path}
          element={
            <MainLayout>
              <SEO title={routes.partnershipSupport.title} />
              <PartnershipSupportPage />
            </MainLayout>
          }
        />
        <Route
          path={routes.contactsUs.path}
          element={
            <MainLayout>
              <SEO title={routes.contactsUs.title} />
              <ContactPage />
            </MainLayout>
          }
        />
        <Route
          path={routes.aboutUs.path}
          element={
            <MainLayout>
              <SEO title={routes.aboutUs.title} />
              <AboutUsPage />
            </MainLayout>
          }
        />
      </>
    ),
  };

  const Root = (
    <>
      <Route path={routes.festivalDemo.path} element={<FestivalDemo />} />
      <Route path={routes.home.path} element={<NewLandingPage />} />
      <Route path={PATHS.emailVerify} element={<EmailVerification />} />
      {/* Private Routes */}
      <Route
        path={routes.profile.path}
        element={
          <PrivatePage>
            <SEO title={routes.profile.title} />
            <Profile />
          </PrivatePage>
        }
      />
      {/* Private Routes */}
      <Route
        path={routes.gba.path}
        element={
          <PrivatePage>
            <SEO title={routes.gba.title} />
            <GBALandingPage />
          </PrivatePage>
        }
      />
      <Route
        path={routes.gbaLogin.path}
        element={
          <MainLayout>
            <SEO title={routes.gbaLogin.title} />
            <GBALogin />
          </MainLayout>
        }
      />
      <Route
        path={routes.login.path}
        element={
          <MainLayout>
            <SEO title={routes.login.title} />
            <Login />
          </MainLayout>
        }
      />
      <Route
        path={routes.register.path}
        element={
          <MainLayout>
            <SEO title={routes.register.title} />
            <Register />
          </MainLayout>
        }
      />
      <Route
        path={routes.settings.path}
        element={
          <PrivatePage>
            <SEO title={routes.settings.title} />
            <SettingPage />
          </PrivatePage>
        }
      />
      {/* <Route
        path="/product/:id"
        element={
          <MainLayout>
            <ProductDetail />
          </MainLayout>
        }
      />
      <Route
        path={routes.cart.path}
        element={
          <MainLayout>
            <SEO title={routes.cart.title} />
            <CartPage />
          </MainLayout>
        }
      />
      <Route
        path={routes.checkout.path}
        element={
          <MainLayout>
            <SEO title={routes.checkout.title} />
            <NewCheckoutPage />
          </MainLayout>
        }
      />
      <Route
        path={routes.orders.path}
        element={
          <MainLayout>
            <SEO title={routes.orders.title} />
            <OrdersHistory />
          </MainLayout>
        }
      /> */}
      <Route
        path={routes.forgotPassword.path}
        element={
          <MainLayout>
            <SEO title={routes.forgotPassword.title} />
            <PasswordForget />
          </MainLayout>
        }
      />
      <Route
        path={routes.resetPassword.path}
        element={
          <MainLayout>
            <SEO title={routes.resetPassword.title} />
            <EmailResetPassword />
          </MainLayout>
        }
      />
      <Route
        path={routes.privacyPolicy.path}
        element={
          <MainLayout>
            <SEO title={routes.privacyPolicy.title} />
            <Privacy />
          </MainLayout>
        }
      />
      <Route
        path={routes.terms.path}
        element={
          <MainLayout>
            <SEO title={routes.terms.title} />
            <Terms />
          </MainLayout>
        }
      />
      <Route
        path={routes.warrantyPolicy.path}
        element={
          <MainLayout>
            <SEO title={routes.warrantyPolicy.title} />
            <WarrantyPolicyPage />
          </MainLayout>
        }
      />
      <Route
        path={routes.paymentPolicy.path}
        element={
          <MainLayout>
            <SEO title={routes.paymentPolicy.title} />
            <PaymentPolicyPage />
          </MainLayout>
        }
      />
      <Route
        path={routes.returnsPolicy.path}
        element={
          <MainLayout>
            <SEO title={routes.returnsPolicy.title} />
            <ReturnsPolicyPage />
          </MainLayout>
        }
      />
      <Route
        path={routes.shipping.path}
        element={
          <MainLayout>
            <SEO title={routes.shipping.title} />
            <ShippingPolicyPage />
          </MainLayout>
        }
      />
      <Route
        path={routes.shipping.path}
        element={
          <MainLayout>
            <SEO title={routes.shipping.title} />
            <ShippingPolicyPage />
          </MainLayout>
        }
      />
      {/* <Route path={routes.terms.path} element={<Terms />} /> */}
      <Route path={routes.returns.path} element={<Refund />} />
      <Route path={routes.legal.path} element={<Legal />} />
      <Route
        path={routes.faq.path}
        element={
          <MainLayout>
            <SEO title={routes.faq.title} />
            <Faq />
          </MainLayout>
        }
      />
      <Route
        path={routes.afterSalesSupport.path}
        element={
          <MainLayout>
            <SEO title={routes.afterSalesSupport.title} />
            <AfterSalesSupportPage />
          </MainLayout>
        }
      />
      <Route
        path={routes.deliverySupport.path}
        element={
          <MainLayout>
            <SEO title={routes.deliverySupport.title} />
            <DeliverySupportPage />
          </MainLayout>
        }
      />
      <Route
        path={routes.productSupport.path}
        element={
          <MainLayout>
            <SEO title={routes.productSupport.title} />
            <ProductSupportPage />
          </MainLayout>
        }
      />
      <Route
        path={routes.partnershipSupport.path}
        element={
          <MainLayout>
            <SEO title={routes.partnershipSupport.title} />
            <PartnershipSupportPage />
          </MainLayout>
        }
      />
      <Route
        path={routes.useRing.path}
        element={
          <MainLayout>
            <SEO title={routes.useRing.title} />
            <UsingRingPage />
          </MainLayout>
        }
      />
      <Route
        path={routes.getRingSize.path}
        element={
          <MainLayout>
            <SEO title={routes.getRingSize.title} />
            <GetRingSizePage />
          </MainLayout>
        }
      />
      <Route
        path={routes.forPayment.path}
        element={
          <MainLayout>
            <SEO title={routes.forPayment.title} />
            <ForPaymentPage />
          </MainLayout>
        }
      />
      <Route
        path={routes.accessControl.path}
        element={
          <MainLayout>
            <SEO title={routes.accessControl.title} />
            <AccessControlPage />
          </MainLayout>
        }
      />
      <Route
        path={routes.preOrder.path}
        element={
          <MainLayout>
            <SEO title={routes.preOrder.title} />
            <PreOrderPolicyPage />
          </MainLayout>
        }
      />
      <Route
        path={routes.contactsUs.path}
        element={
          <MainLayout>
            <SEO title={routes.contactsUs.title} />
            <ContactPage />
          </MainLayout>
        }
      />
      <Route
        path={routes.aboutUs.path}
        element={
          <MainLayout>
            <SEO title={routes.aboutUs.title} />
            <AboutUsPage />
          </MainLayout>
        }
      />
    </>
  );

  return (
    <div className={`theme-${theme}`}>
      <Routes>
        {WEB_TYPE && !!WEB_TYPE_NAME[WEB_TYPE] ? roots[WEB_TYPE] : Root}

        {/* Private Routes */}
        <Route
          path="*"
          element={
            <MainLayout>
              {/* <SEO title={t("title.pageNotFound")} /> */}
              {/* <PageNotFound /> */}
              <ComingSoon />
            </MainLayout>
          }
        />
      </Routes>
    </div>
  );
}

// function Layout() {
//   return (
//     <div>
//       {/* A "layout route" is a good place to put markup you want to
//           share across all the pages on your site, like navigation. */}
//       <nav>
//         <ul>
//           <li>
//             <Link to="/">Home</Link>
//           </li>
//           <li>
//             <Link to="/about">About</Link>
//           </li>
//           <li>
//             <Link to="/dashboard">Dashboard</Link>
//           </li>
//           <li>
//             <Link to="/nothing-here">Nothing Here</Link>
//           </li>
//         </ul>
//       </nav>

//       <hr />

//       {/* An <Outlet> renders whatever child route is currently active,
//           so you can think about this <Outlet> as a placeholder for
//           the child routes we defined above. */}
//       <Outlet />
//     </div>
//   );
// }

// function Home() {
//   return (
//     <div>
//       <h2>Home</h2>
//       <TestComponent />
//       <I18nComponent />
//     </div>
//   );
// }

// function About() {
//   return (
//     <div>
//       <h2>About</h2>
//     </div>
//   );
// }

// function Dashboard() {
//   return (
//     <div>
//       <h2>Dashboard</h2>
//     </div>
//   );
// }

// function NoMatch() {
//   return (
//     <div>
//       <h2>Nothing to see here!</h2>
//       <p>
//         <Link to="/">Go to the home page</Link>
//       </p>
//     </div>
//   );
// }
