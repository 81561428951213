// import { ShoppingCartOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import MediaQuery from "react-responsive";

// import { Link } from "react-router-dom";
import StyledButton from "../button/StyledButton";

export default function BannerVideo() {
  // const videoRef = useRef<any>(null);
  // useEffect(() => {
  //   videoRef.current?.play?.();
  // }, []);
  const { t } = useTranslation();

  const handleScroll = () => {
    const el = document.getElementById("function");
    const top = Number(el?.offsetTop);
    window.scroll({
      top: top - 50,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="banner-video">
      {/* <div className="pointer">
        <PlayCircleSvg />
      </div> */}

      <MediaQuery minWidth={768}>
        <div className="banner-video__video-content">
          <ReactPlayer
            width="100%"
            height="100%"
            loop
            muted
            playing
            playsinline
            url="https://video.wixstatic.com/video/9a71f9_bd8c8aafbfca4b7dbcab94c4e623c52d/1080p/mp4/file.mp4"
          />
          {/* <video autoPlay muted loop>
            <source src="static/videos/desktop-video.mp4" type="video/mp4" />
          </video> */}
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={767}>
        <div className="banner-video__video-content">
          <ReactPlayer
            // ref={videoRef}
            width="100%"
            height="100%"
            loop
            muted
            playing
            playsinline
            url="https://video.wixstatic.com/video/9a71f9_c628464571024315928dc0894e2a3c50/1080p/mp4/file.mp4"
          />
          {/* <video autoPlay muted loop preload="auto">
            <source
              src="https://artsandculture.withgoogle.com/gcs/national-parks-service/en-us/_KENAI_INTRO_FROM_MAP_081516_mobile.mp4"
              type="video/mp4"
            />
          </video> */}
        </div>
      </MediaQuery>

      <div className="banner-video__content">
        <div className="banner-video__title">
          {t("page.landing.part.bannerVideo.title")}
        </div>
        {/* <div className="banner-video__description">
          Nothing else than everything you need
        </div> */}
        <div className="banner-video__button-wrapper flex">
          {/* <ShoppingCartOutlined /> */}
          {/* <Link to="/product/1">
            <StyledButton className="outline">
              {t("page.landing.part.bannerVideo.preOrder")}
            </StyledButton>
          </Link> */}
          {/* <div className="w-4" /> */}
          <StyledButton className="light-on-dark" onClick={handleScroll}>
            {t("page.landing.part.bannerVideo.moreInfo")}
          </StyledButton>
        </div>
      </div>
    </div>
  );
}
